@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

html, body{
	margin:0;
	height:100%;
	overflow:hidden;

}


body {
  margin: 0;
  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  font-family: 'Fahkwang';
}

p, li, a {
  color: white;
  text-decoration: none;
}
