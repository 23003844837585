@import './breakpoints.scss';

nav.nav-home {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50vw; 
	height: 44px;
	top: 34vh;
	margin: 0 25vw; 
	transform: translateY(120px);
    animation: fadein 2s ease-in;
    animation-delay: 3s;
    animation-fill-mode: forwards;
	z-index: 1000;
    opacity: 0;
    @media only screen and (max-width: 1024px) {
        width: auto;
    }

    @media only screen and (max-width: 650px) {
        display: none;
    }

    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 125px;
        width: 100%;
        height: 125px;
        align-items: center; 
        text-align: center;
    
        font-family: 'Lato', cursive;
        color: #A3ADB3;
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 5px;
    
        position: relative;
        
        .navitem {
            position: relative; 
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            text-align: center;
            width: 85px;
            height: 85px;
    
            transform: scale(0.8);
            transition: all ;
            
        
            &.navitem--disc {
                background-image: url(../img/geo/disc.png);
            }
            &.navitem--studio {
                background-image: url(../img/geo/studio.png); 
            }
            &.navitem--services {
                background-image: url(../img/geo/services.png);
            }
        }
        
        span {
            text-align: center;
            text-transform: uppercase;
            display: block;
            margin-top: 20px;
            line-height: 1.2rem;
            position: absolute;
            top: 105px;
            font-weight: 600;
            /* color: white; */
            min-height: 30px;
            display: flex;
            align-items: center;
            color: white;
            font-weight: 300;
        }
    
        // animations
    
        .navitem {
            transform: scale(0.9);
            opacity: 0.8;
            transition: all 0.25s cubic-bezier(0.0, 0.0, 0.58, 1.0);
            transform-style: preserve-3d;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
    
            & + span {
                opacity: 0;
                transition: all 0.4s ease;
            }
    
            &:hover {
                transform: scale(1);
                opacity: 1;
                & + span {
                    opacity: 1;
                }
            }
        }
    
    
    }
}





 


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1;}
}
