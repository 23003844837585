@import './breakpoints.scss';

 
.master-brand {
	z-index: 100;
	position: relative; 
	background-image: url(../img/brand_desktop.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	width: 51vw; 
	height: 44px;
	top: 28vh;
	margin: auto; 
	animation: fadein 3s ease-in;

	@media only screen and (max-width: 1024px) {
		width: 60vw; 
		top: 20vh;
	}
 	
	p {

		font-family: 'Lato', cursive;
		color: #ff2f2f;
		font-size: 10px;
		text-align: center;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 9px;
		transform: translateY(90px);
		opacity: 0.7;
		animation: fadein 5s ease-in;
	}
}



@media only screen and (max-width: 650px) {
	.master-brand {
		width: 470px;
		margin: unset;
		transform: rotate(270deg);
		height: 90px;
		top: 250px;
		left: -190px;
		
		p {
			display: none;
		}
		
		p.countdown {
			color: #ff2f2f;
			font-size: 10px;
			letter-spacing: 4px;
		}
	}
}


.social {
	z-index: 100;
	font-family: 'Lato', cursive;
	font-size: 10px;
	text-align: center;
	width: 100%;
	color: white;
	bottom: 30px;
	position: absolute;
	animation: fadein 2s ease-in;
	animation-delay: 4s;
	animation-fill-mode: forwards;
	opacity: 0;
}

.social a {
	padding: 0 5px;
	color: white;
	font-weight: 300;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 2px;
	opacity: 0.85;
	animation: fadein 10s ease-in;
	transition: color 0.2s ease;
	}

	.social a:hover { 
		color: #ff2f2f;
	}


	@media only screen and (max-width: 650px) {
		.social {
			display: flex;
			flex-direction: column;
			text-align: right;
			right: 10px;
			bottom: 10px;
		} 

		.social a {
			margin-bottom: 15px;
		}
	}


@keyframes blinker {
  50% {
    opacity: 1;
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// @keyframes moveUp  {
//     from { transform: translateY(0); }
//     to   { transform: translateY(-40px);}
// }

.enable-audio {
	font-family: 'Lato', cursive;
    color: aliceblue;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
	padding: 10px 20px; 
    display: block;
    background: black;
    width: fit-content;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
	position: relative; 
    letter-spacing: 5px;
	opacity: 0.8;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		color: #ff2f2f;
	}

	&.off {
		opacity: 0;
	}
	@media only screen and (max-width: 1024px) {
		display: none;
	}
}