@import './breakpoints.scss';


main {
  	z-index: 100;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100vw;
    height: 100vh;
	//background-image: url('../img/legusi_desktop_full.png');
	// background-image: url('../img/alt_desktop_full.png');
	// background-color: black;
	background-size: cover;
	background-repeat: no-repeat;
	animation: fadein 2s ease-in;
	
    animation-delay: 0s;
    animation-fill-mode: forwards;
    opacity: 0;

	> section {
		@media only screen and (max-width: 650px) {
			display: none;
		}
	}

} 

.mountain,
section img.feature-single {
	min-height:100%;
	min-width:100%;
	height:auto;
	width:auto;
	position:absolute;
	top:-100%; 
	bottom:-100%;
	left:-100%; 
	right:-100%;
	margin:auto;
	z-index: -1;
	// animation: fadein 3s ease;
}

header {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-items: center;
	align-items: center;
	justify-content: space-between; 
	padding: 2vw;
	width: 98%;

	&-brand {
		display: flex;
		flex-direction: row;
	}

	.brand {
		z-index: 100;
		position: relative; 
		background-image: url(../img/brand_desktop.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		width: 40vw; 
		height: 34px;
		top: 0px;
		left: 1vw;
		margin: 0 1vw;  
		text-indent: -10000px;
		a  {
			display: block;
		}
	}

	h2 {

		font-family: 'Lato', cursive;
		color:#ff2f2f;
		font-size: 10px;
		text-align: end;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 6px;
		text-transform: uppercase;
		animation: fadein 3s ease-in;
		margin-top: 15px;
		margin-right: -5px;
		@media only screen and (max-width: 1024px) {
		
				font-size: 7px;
				text-align: end;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 6px;
				text-transform: uppercase;
				animation: fadein 3s ease-in;
				margin-top: 10px;
				margin-right: -55px;
		}

		@media only screen and (max-width: 650px) {
			display: none;
		}
	}

	
	nav {
		height: fit-content;
		flex-direction: row;
		width: fit-content;
		display: flex;
		top: -10px;
    	position: relative;
		@media only screen and (max-width: 1024px) {
			width: 40%;
		} 

		@media only screen and (max-width: 650px) {
			display: none;
		} 

		a {
			display: block; 
			max-width: 125px;
			width: 125px;
			height: 85px;  
			font-family: 'Lato', cursive;
			color: #A3ADB3;
			font-size: 11px;
			text-align: center;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 5px;
			position: relative;
			margin: auto 10px;
			@media only screen and (max-width: 1024px) {
				margin: auto 0;
			} 

			// border-bottom: 1px solid transparent;
			transition: all 0.2s ease;

			&:hover {
				transition: all 0.2s ease;
				span {
					opacity: 1;
					transition: all 0.2s ease;
					color: #ff2f2f;
					// border-bottom: 1px solid white;		
				}
		
				.navitem {
					transform: scale(0.8);
					opacity: 1;
				}
			}
        
        .navitem {
            position: relative; 
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            text-align: center;
            width: 85px;
            height: 85px;
            opacity: 1;
            transform-style: preserve-3d;
            backface-visibility: hidden;
    
            transform: scale(0.7);
			transition: all 0.2s ease;
            
        
            &.navitem--disc {
                background-image: url(../img/geo/disc.png);
            }
            &.navitem--studio {
                background-image: url(../img/geo/studio.png);
                // background-size: 90%;
                // background-position-y: top;
            }
            &.navitem--services {
                background-image: url(../img/geo/services.png);
            }
        }
        
        span {
           
			position: absolute;
			bottom: -45px;
			left: -30px;

			display: flex;
			align-items: center;
			
			width: 150px; 
			text-align: center;
			justify-content: center;

			opacity: 0;
			transition: all 0.2s ease;
			
			font-weight: 500;
			min-height: 40px;
			text-transform: uppercase;
			line-height: 1.1rem;
			color: white; 
        }
     
	}
}
	

	@media only screen and (max-width: 650px) {
		.brand {
			width: 60vh;
			margin: unset;
			margin-left: -40vw;
			transform: rotate(270deg);
			height: 90px;
			top: 33vh;
		}
		.brand p {
			font-size: 10px;
			letter-spacing: 4px;
			color: white;
			display: none;
		} 

	} 
}

.content-master { 
	width: 90vw;
    height: 50vw;
    max-height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    overflow: hidden;
    // border: 1px solid #ff2f2f;
}

@keyframes blinker {
	50% {
		opacity: 1;
	}
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
  