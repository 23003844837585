@import './breakpoints.scss';

main.services-master {
    // background-image: url(../img/legusi_desktop_full.png);
    background-size: cover;
    a {
        color: #ff2f2f;
        border-bottom: 0;
        &:hover {
            transition: 0.2s all ease;
            border-bottom: 1px dashed #ff2f2f;
        }
    }
}

.services { 
    justify-content: space-between;
    width: 90%;
    height: 70%;
    display: flex;
    flex-flow: row;
    position: relative;
    margin-top: 5vw;
    @media only screen and (max-width: 1024px) {
        flex-flow: column;
        height: auto;
        width: 95%;
        margin-top: 1vw;
        display: flex;
        height: 100%;
        justify-content: space-evenly;
    }

    .services-panel {
        margin-top: 0vw;
        width: 31vw;
        height: fit-content; 
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            height: auto;
        }
    
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: auto; right: auto;
            top: 0;
            z-index: -1;
            mix-blend-mode: difference;
            background: #ff2f2f;
            opacity: 0.3;
        }

        
    }

    .bio-panel {
        width: 45vw;
        margin-bottom: 5vw;
        align-self: flex-end;
        height: auto;
        // max-height: 35vw;
        position: relative;
        height: fit-content;
        overflow: hidden;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            margin-top: 40px;
            margin-bottom: 0vw;
            
        }

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: auto; right: auto;
            top: 0;
            z-index: -1;
            mix-blend-mode: color;
            background: white;
            opacity: 1;
        }

       

        .inner {
            padding: 20px 20px 40px 40px;
           
        }

        .bio-scroll {
            overflow: scroll;
            padding: 0;
            /* overflow-x: auto; */
            padding-right: 20px;
            height: fit-content;
            position: relative;
            max-height: 370px;
            overflow-y: auto;
            overflow-x: auto;

            @media only screen and (max-width: 1024px) {
                max-height: 150px;
            }

            p:nth-child(1) {
                margin-top: 0;
            }

            /* ===== Scrollbar CSS ===== */
 

        }
    }

    h3 {
        color: white;
    }
    
    
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #949494 #000000;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: #000000;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #949494;
        border-radius: 0px;
        border: 3px solid #949494;
    }
}


 